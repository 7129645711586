













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TwoColumnRow extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly firstIsOnRight!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly smallerGap!: boolean;

  get swapColumns(): boolean {
    return this.firstIsOnRight && this.$vuetify.breakpoint.mdAndUp;
  }
}
