var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"play-container d-flex flex-column align-center justify-center",class:[
      !_vm.isOpen
        ? _vm.isForTalent
          ? 'background-talent dimensions-talent'
          : 'background-company dimensions-company'
        : _vm.isForTalent
        ? 'dimensions-talent'
        : 'dimensions-company' ],on:{"click":_vm.openMovie}},[(!_vm.isOpen)?[_c('v-avatar',{attrs:{"size":"80"}},[_c('v-img',{attrs:{"src":"/play-button-circle.svg"}})],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-medium grey--text pt-4 accent--text"},[_vm._v("\n        "+_vm._s(_vm.$t("Se filmen:"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"gr-heading-medium white--text pt-1"},[_vm._v("\n        "+_vm._s(_vm.$t("Så funkar Gritify"))+"\n      ")])]:_vm._e()],2),_vm._v(" "),_c('GrMoviePlayerModal',{ref:"GrMoviePlayerModal",attrs:{"is-for-talent":_vm.isForTalent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }