


































import { Vue, Component, Prop } from "vue-property-decorator";
import GrMoviePlayerModal from "./GrMoviePlayerModal.vue";

@Component({
  components: {
    GrMoviePlayerModal,
  },
})
export default class GrMoviePlayer extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isForTalent!: boolean;

  isOpen = false;

  $refs!: Vue["$refs"] & {
    GrMoviePlayerModal: GrMoviePlayerModal;
  };

  async openMovie() {
    this.isOpen = true;
    await this.$refs.GrMoviePlayerModal?.open();
    this.isOpen = false;
  }
}
