import { render, staticRenderFns } from "./GrMoviePlayerModal.vue?vue&type=template&id=3e51ba05&scoped=true&"
import script from "./GrMoviePlayerModal.vue?vue&type=script&lang=ts&"
export * from "./GrMoviePlayerModal.vue?vue&type=script&lang=ts&"
import style0 from "./GrMoviePlayerModal.vue?vue&type=style&index=0&id=3e51ba05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e51ba05",
  null
  
)

/* custom blocks */
import block0 from "./GrMoviePlayerModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VDialog})
