










































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CompanyCarousel extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Boolean, default: false })
  readonly dark!: boolean;

  get items() {
    return this.getManyFromList(this.itemsInternal);
  }

  get items2() {
    return this.getManyFromList(this.itemsInternal);
  }

  getManyFromList<T extends { companyName: string }>(list: T[]) {
    const hej = Array.from({ length: 3 }, (_, index) => {
      return list.map(h => {
        return {
          ...h,
          companyName: h.companyName + index,
        };
      });
    });

    return hej.reduce((acc: any[], arr) => {
      return [...acc, ...arr];
    }, []);
  }

  itemsInternal = [
    {
      imageUrl: "/companyCarouselImages/AtlasCopco.png",
      text: "Senior Developer",
      companyName: "Atlas Copco",
    },
    {
      imageUrl: "/companyCarouselImages/Dibz.png",
      text: "Head of Growth",
      companyName: "Dibz",
    },
    {
      imageUrl: "/companyCarouselImages/Optilon.png",
      text: "Key Account Manager",
      companyName: "Optilon",
    },
    {
      imageUrl: "/companyCarouselImages/P27.png",
      text: "Comp & Ben Specialist",
      companyName: "P27 Nordic Payments",
    },
    {
      imageUrl: "/companyCarouselImages/Pedago.png",
      text: "Lead Mobile Developer",
      companyName: "Pedago",
    },
    {
      imageUrl: "/companyCarouselImages/Granges.png",
      text: "Strategy & M&A Manager",
      companyName: "Gränges",
    },
    {
      imageUrl: "/companyCarouselImages/Estrid.png",
      text: "Chief Financial Officer",
      companyName: "Estrid",
    },
    {
      imageUrl: "/companyCarouselImages/Visa.png",
      text: "Senior Management Consultant",
      companyName: "Visa",
    },
    {
      imageUrl: "/companyCarouselImages/Narva.png",
      text: "Senior Communication Consultant",
      companyName: "Narva",
    },
    {
      imageUrl: "/companyCarouselImages/Oneflow.png",
      text: "Legal Counsel",
      companyName: "Oneflow",
    },
    {
      imageUrl: "/companyCarouselImages/PlantVision.png",
      text: "QA Engineer",
      companyName: "PlantVision",
    },
    {
      imageUrl: "/companyCarouselImages/PE Accounting.png",
      text: "Senior Accountant",
      companyName: "PE Accounting",
    },
    {
      imageUrl: "/companyCarouselImages/WeAudit.png",
      text: "Auditor",
      companyName: "WeAudit",
    },
    {
      imageUrl: "/companyCarouselImages/Eletive.png",
      text: "Account Executive",
      companyName: "Eletive",
    },
    {
      imageUrl: "/companyCarouselImages/Neovici.png",
      text: "People Partner",
      companyName: "Neovici",
    },
    {
      imageUrl: "/companyCarouselImages/Asurgent.png",
      text: "DevOps Engineer",
      companyName: "Asurgent",
    },
    {
      imageUrl: "/companyCarouselImages/VXfiber.png",
      text: "Business Developer",
      companyName: "VX Fiber",
    },
    {
      imageUrl: "/companyCarouselImages/Evoko.png",
      text: "Design Engineer",
      companyName: "Evoko",
    },
    {
      imageUrl: "/companyCarouselImages/Pineberry.png",
      text: "SEO Consultant",
      companyName: "Pineberry",
    },
    {
      imageUrl: "/companyCarouselImages/Cartina.png",
      text: "UX Designer",
      companyName: "Cartina",
    },
    {
      imageUrl: "/companyCarouselImages/Serendipity.png",
      text: "M&A Lawyer",
      companyName: "Serendipity",
    },
    {
      imageUrl: "/companyCarouselImages/Forefront.png",
      text: "Cloud Architect",
      companyName: "Forefront",
    },
    {
      imageUrl: "/companyCarouselImages/Qred.png",
      text: "Talent Acquisition Partner",
      companyName: "Qred",
    },
    {
      imageUrl: "/companyCarouselImages/Norditek.png",
      text: "Product Engineer",
      companyName: "Norditek",
    },
    {
      imageUrl: "/companyCarouselImages/Censio.png",
      text: "Transfer Pricing Specialist",
      companyName: "Censio",
    },
    {
      imageUrl: "/companyCarouselImages/Airmee.png",
      text: "Head of Marketing",
      companyName: "Airmee",
    },
    {
      imageUrl: "/companyCarouselImages/Actic.png",
      text: "Group Business Controller",
      companyName: "Actic",
    },
    {
      imageUrl: "/companyCarouselImages/Klarna.png",
      text: "Product Manager",
      companyName: "Klarna",
    },
    {
      imageUrl: "/companyCarouselImages/Novedo.png",
      text: "M&A Manager",
      companyName: "Novedo",
    },
    {
      imageUrl: "/companyCarouselImages/Tibber.png",
      text: "Data Engineer",
      companyName: "Tibber",
    },
  ];
}
