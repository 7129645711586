




































import { Vue, Component, Prop } from "vue-property-decorator";

export type GrMoviePlayerModalResult = {
  type: "Cancel";
};

@Component
export default class GrMoviePlayerModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isForTalent!: boolean;

  resolve: ((v: GrMoviePlayerModalResult) => void) | null = null;
  isOpen = false;

  $refs!: Vue["$refs"] & {
    videoElement: HTMLVideoElement;
  };

  async open(): Promise<GrMoviePlayerModalResult> {
    this.isOpen = true;
    await this.$nextTick();
    this.playVideo();

    return new Promise<GrMoviePlayerModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  playVideo() {
    this.$refs.videoElement?.play();
  }

  pauseVideo() {
    this.$refs.videoElement?.pause();

    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.isOpen = false;
  }

  get showSwedishMovie(): boolean {
    return this.$i18n.locale === "sv";
  }
}
