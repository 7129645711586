import { render, staticRenderFns } from "./GrMoviePlayer.vue?vue&type=template&id=7b7af30a&scoped=true&"
import script from "./GrMoviePlayer.vue?vue&type=script&lang=ts&"
export * from "./GrMoviePlayer.vue?vue&type=script&lang=ts&"
import style0 from "./GrMoviePlayer.vue?vue&type=style&index=0&id=7b7af30a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b7af30a",
  null
  
)

/* custom blocks */
import block0 from "./GrMoviePlayer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CstartPageNew%5CGrMoviePlayer.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VImg})
